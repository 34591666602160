import React from "react";

const MaterialityAnalysisPage = () => {
  return (
    <>
      <h2>Materiality Analysis Page</h2>
    </>
  );
};

export default MaterialityAnalysisPage;
