import styled from "styled-components";

export const StyledHeroSection = styled.section`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    ${({ theme }) => theme.colors.blue},
    ${({ theme }) => theme.colors.green}
  );
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const HeroContent = styled.div`
  width: 100%;
  max-width: 90rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: justify;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const SectionTitle = styled.h1`
  font-size: 3rem;
  margin: 1.5rem 0;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 2rem;
  }
`;

export const SectionBody = styled.div`
  margin: 2.5rem 0;
  text-align: justify;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
`;

export const SectionColumns = styled.p`
  font-size: 1.7rem;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.3rem;
  }
`;
