import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  TeamContainer,
} from "../styles/sections/TeamSection.styled";

const TeamSection = () => {
  return (
    <>
      <h2>Team</h2>
      <TeamContainer>
        <Card>
          <CardImg src="./images/person_placeholder.jpg" />
          <CardBody>
            <h4>Anders Hanning</h4>
            <p>CEO & Co-founder</p>
          </CardBody>
        </Card>
        <Card>
          <CardImg src="./images/person_placeholder.jpg" />
          <CardBody>
            <h4>Johan Lodin</h4>
            <p>Co-founder</p>
          </CardBody>
        </Card>
      </TeamContainer>
    </>
  );
};

export default TeamSection;
