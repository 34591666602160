import React from "react";
import {
  HeroContent,
  SectionBody,
  SectionColumns,
  SectionTitle,
  StyledHeroSection,
} from "../styles/sections/HeroSection.styled";
import { SectionContainer } from "../styles/common/Container.styled";

const HeroSection = () => {
  return (
    <SectionContainer>
      <StyledHeroSection>
        <HeroContent>
          <SectionTitle>
            Enweave is an AI-powered sustainability consultant - fully digital,
            supporting your company's sustainability journey
          </SectionTitle>
          <SectionBody>
            <SectionColumns>
              Many small and medium-sized companies don't have the time, money
              or staff to work with sustainability
            </SectionColumns>
            <SectionColumns>
              With upcoming requirements for sustainability reporting (e.g.
              CSRD), companies need to create sustainability visions, goals and
              key performance indicators - to meet both customers' and society's
              demands
            </SectionColumns>
            <SectionColumns>
              Our AI-powered sustainability consultant helps small and
              medium-sized businesses create a lightweight sustainability
              management system quickly and cost-effectively!
            </SectionColumns>
          </SectionBody>
        </HeroContent>
      </StyledHeroSection>
    </SectionContainer>
  );
};

export default HeroSection;
