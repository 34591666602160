import React from "react";

const FeasibilityConsultantPage = () => {
  return (
    <>
      <h2>Sustainability Consultant Page</h2>
    </>
  );
};

export default FeasibilityConsultantPage;
