import React from "react";

const PortfolioAnalysisPage = () => {
  return (
    <>
      <h2>Portfolio Analysis Page</h2>
    </>
  );
};

export default PortfolioAnalysisPage;
