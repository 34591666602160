export const NavItems = [
  {
    link: "/",
    text: "Home",
  },
  {
    link: "/materiality-analysis",
    text: "Materiality Analysis",
  },
  {
    link: "/feasibility-consultant",
    text: "Sustainability Consultant",
  },
  {
    link: "/portfolio-analysis",
    text: "Portfolio Analysis",
  },
  {
    hasSocialIcons: true,
  },
  {
    hasCTA: true,
  },
];
