import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  CollaborationContainer,
} from "../styles/sections/CollaborationSection.styled";

const CollaborationsSection = () => {
  return (
    <>
      <h2>Collaborations</h2>
      <CollaborationContainer>
        <Card>
          <CardImg src="./images/cv_logo_black.svg" />
          <CardBody>
            <h4>Investors & Incubator</h4>
          </CardBody>
        </Card>
        <Card>
          <CardImg src="./images/Vinnova_green_payoff_RGB.png" />
          <CardBody>
            <h4>Financing "Innovative Startups"</h4>
          </CardBody>
        </Card>
        <Card>
          <CardImg src="./images/connect.svg" />
          <CardBody>
            <h4>Incubator</h4>
          </CardBody>
        </Card>
        <Card>
          <CardImg src="./images/almi.svg" />
          <CardBody>
            <h4>Financing</h4>
          </CardBody>
        </Card>
        <Card>
          <CardImg src="./images/VG_med_stöd_från_Kontor_svart.png" />
          <CardBody>
            <h4>Financing</h4>
          </CardBody>
        </Card>
      </CollaborationContainer>
    </>
  );
};

export default CollaborationsSection;
