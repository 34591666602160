import React from "react";

const TestimonialsSection = () => {
  return (
    <>
      <h2>Testimonials</h2>
    </>
  );
};

export default TestimonialsSection;
