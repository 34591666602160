import React from "react";
import AboutSection from "../sections/AboutSection";
import CollaborationsSection from "../sections/CollaborationsSection";
import HeroSection from "../sections/HeroSection";
import NewsLetterSection from "../sections/NewsLetterSection";
import TeamSection from "../sections/TeamSection";
import TestimonialsSection from "../sections/TestimonialsSection";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <AboutSection />
      <NewsLetterSection />
      <CollaborationsSection />
      <TestimonialsSection />
      <TeamSection />
    </>
  );
};

export default HomePage;
