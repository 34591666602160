import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import HomePage from "./components/pages/HomePage";
import { GlobalStyles } from "./components/styles/Globals.styled";
import MaterialityAnalysisPage from "./components/pages/MaterialityAnalysisPage";
import FeasibilityConsultantPage from "./components/pages/SustainabilityConsultantPage";
import PortfolioAnalysisPage from "./components/pages/PortfolioAnalysisPage";
import Navbar from "./components/layout/Navbar";
import Footer from "./components/layout/Footer";

function App() {
  const lightTheme = {
    colors: {
      primary: "rgba(240, 241, 231, 1)",
      lightPrimary: "rgba(203, 203, 203, 1)",
      secondary: "rgba(13, 13, 13, 1)",
      lightSecondary: "rgba(50, 50, 50, 1)",
      green: "rgba(193, 240, 194, 1)",
      orange: "rgba(240, 129, 130, 1)",
      blue: "rgba(179, 223, 226, 1)",
    },
    mobile: "1080px",
    transition: "all 650ms ease-in-out",
  };

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/materiality-analysis"
              element={<MaterialityAnalysisPage />}
            />
            <Route
              path="/feasibility-consultant"
              element={<FeasibilityConsultantPage />}
            />
            <Route
              path="/portfolio-analysis"
              element={<PortfolioAnalysisPage />}
            />
            <Route path="*" element={<HomePage />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
