import React, { useState } from "react";
import {
  LineOne,
  LineThree,
  LineTwo,
  Logo,
  MenuIcon,
  Nav,
  NavItemStyled,
  NavLink,
  NavList,
  SocialIcons,
} from "../styles/layout/Navbar.styled";
import { NavItems } from "../../data/NavItems";
import { FaGlobe, FaLinkedin } from "react-icons/fa";
import { CTAButton } from "../styles/common/CTAButton.styled";
import useScrollDirection from "../../hooks/useScrollDirection";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const scrollDirection = useScrollDirection("down");
  return (
    <>
      <Nav scrollDirection={scrollDirection}>
        <div>
          <a href="#hero-section">
            <Logo>Enweave</Logo>
          </a>
        </div>
        <MenuIcon onClick={() => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
          <LineOne menuOpen={menuOpen} />
          <LineTwo menuOpen={menuOpen} />
          <LineThree menuOpen={menuOpen} />
        </MenuIcon>
        <NavList menuOpen={menuOpen}>
          {NavItems &&
            NavItems.map((navItem, index) => (
              <NavItemStyled
                key={index}
                index={index}
                menuOpen={menuOpen}
                onClick={() => setMenuOpen(false)}
              >
                {navItem.text && (
                  <NavLink to={navItem.link}>{navItem.text}</NavLink>
                )}
                {navItem.hasSocialIcons && (
                  <>
                    <SocialIcons>
                      <NavLink
                        to="https://interview.vundavalli.me"
                        target="_blank"
                      >
                        <FaGlobe />
                      </NavLink>
                      <NavLink
                        to="https://www.linkedin.com/company/enweave/"
                        target="_blank"
                      >
                        <FaLinkedin />
                      </NavLink>
                    </SocialIcons>
                  </>
                )}
                {navItem.hasCTA && (
                  <CTAButton href="mailto:contact@vundavalli.me">
                    Email Us
                  </CTAButton>
                )}
              </NavItemStyled>
            ))}
        </NavList>
      </Nav>
    </>
  );
};

export default Navbar;
