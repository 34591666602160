import React from "react";
import {
  FooterLink,
  FooterRow,
  StyledFooter,
} from "../styles/layout/StyledFooter.styled";
import { FaEnvelope, FaLinkedin, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const Footer = () => {
  return (
    <>
      <StyledFooter>
        <FooterRow>
          <FooterLink to="mailto:contact@vunavalli.me">
            <FaEnvelope />
            contact@vundavalli.me
          </FooterLink>
          <FooterLink to="tel:+46768001262">
            <FaPhoneAlt />
            +46 (0) 76-800-1262
          </FooterLink>
          <FooterLink
            to="https://www.linkedin.com/company/enweave/"
            target="_blank"
          >
            <FaLinkedin />
            LinkedIn
          </FooterLink>
          <FooterLink
            to="https://maps.app.goo.gl/EcPAZJxKSRGqobh36"
            target="_blank"
          >
            <FaLocationDot />
            Gothenburg
          </FooterLink>
        </FooterRow>
        <FooterRow>
          <div>&copy; {new Date().getFullYear()} Sravani Vundavalli</div>
          <div>All rights reserved</div>
        </FooterRow>
      </StyledFooter>
    </>
  );
};

export default Footer;
